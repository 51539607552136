import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Box, Form, FormField, TextInput, Button, TextArea, Paragraph, Text } from 'grommet';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import * as Yup from 'yup';

const StyledSent = styled(Paragraph)`
    ${({ theme }) => css`
        text-align: center;
        background-color: ${theme.global.colors['status-ok']};
        color: #fff;
        border-radius: 4px;
        padding: ${theme.global.edgeSize.small};
    `}
`;
const StyledErrors = styled(StyledSent)`
    ${({ theme }) => css`
        background-color: ${theme.global.colors['status-error']};
    `}
`;

const EmailSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
    message: Yup.string()
        .min(2, 'Too Short!')
        .max(1000, 'Too Long!')
        .required('Required'),
    phone: Yup.string().required('Required'),
    postcode: Yup.string()
        .matches(
            /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
            { excludeEmptyString: true, message: 'Please enter a valid postcode' }
        )
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

const initialValues = {
    name: '',
    email: '',
    phone: '',
    postcode: '',
    message: '',
};

const SEND_EMAIL = gql`
    mutation SendEmail($input: SendEmailInput!) {
        sendEmail(input: $input) {
            sent
            origin
            message
        }
    }
`;
const StyledFormWrap = styled(Box)`
    width: 100%;
    max-width: 600px;
`;

export const ContactForm = ({ subject = 'Email from website' }) => {
    const hasSentTracking = useRef(false);

    function gtag_report_conversion() {
        try {
            const callback = function() {
                console.log('Tracking sent');
            };
            gtag('event', 'conversion', {
                send_to: 'AW-16913707997/dgbQCJKXo6caEN3nioE_',
                value: 1.0,
                currency: 'GBP',
                event_callback: callback,
            });
            return false;
        } catch (e) {
            console.log('Error tracking conversion', e);
        }
    }

    const [sendEmail, { data, loading, error }] = useMutation(SEND_EMAIL);

    useEffect(() => {
        if (!loading && !error && data && data.sendEmail.sent) {
            //
        }
        console.log('error', error);
    }, [data, error, loading]);

    const hasSent = !loading && data?.sendEmail?.sent;

    useEffect(() => {
        if (hasSent && !hasSentTracking.current) {
            hasSentTracking.current = true;
            gtag_report_conversion();
        }
    }, [hasSent]);

    return (
        <>
            <StyledFormWrap alignSelf="center">
                {!hasSent && (
                    <Formik
                        validateOnChange
                        validateOnBlur={false}
                        initialValues={initialValues}
                        validationSchema={EmailSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            const input = {
                                clientMutationId: 'ContactForm',
                                replyTo: `${values.name} <${values.email}>`,
                                subject,
                                body: `
                Name: ${values.name} <br />
                Phone: ${values.phone} <br />
                Email: ${values.email} <br />
                Postcode: ${values.postcode} <br />
                Message:  <br /> ${values.message} <br />
   
         `,
                            };

                            try {
                                await sendEmail({ variables: { input } });

                                trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'Contact Form',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Sent',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: 'Website Enquiry',
                                });

                                setSubmitting(false);
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            setFieldValue,
                            validateForm,
                            setFieldTouched,
                            /* and other goodies */
                        }) => (
                            <Form onSubmit={handleSubmit} onChange={validateForm}>
                                <FormField
                                    label="Your Name *"
                                    required
                                    name="name"
                                    error={touched?.name && errors?.name}
                                >
                                    <TextInput
                                        name="name"
                                        required
                                        onChange={handleChange}
                                        value={values.name}
                                        autoComplete="name"
                                    />
                                </FormField>
                                <FormField
                                    label="Your Email *"
                                    required
                                    name="email"
                                    error={touched?.email && errors?.email}
                                >
                                    <TextInput
                                        name="email"
                                        type="email"
                                        required
                                        onChange={handleChange}
                                        value={values.email}
                                        autoComplete="email"
                                    />
                                </FormField>
                                <FormField
                                    label="Your Phone number *"
                                    required
                                    name="phone"
                                    error={touched?.phone && errors?.phone}
                                >
                                    <TextInput
                                        name="phone"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={values.phone}
                                        autoComplete="tel"
                                    />
                                </FormField>

                                <FormField
                                    label="Your Postcode *"
                                    name="postcode"
                                    onChange={handleChange}
                                    error={touched?.postcode && errors?.postcode}
                                    autoComplete="postcode"
                                    required
                                >
                                    <TextInput name="postcode" onChange={handleChange} value={values.postcode} />
                                </FormField>
                                <FormField
                                    label="Your Message *"
                                    required
                                    name="message"
                                    onChange={handleChange}
                                    error={touched?.message && errors?.message}
                                >
                                    <TextArea
                                        name="message"
                                        onChange={handleChange}
                                        value={values.message}
                                        required
                                        rows={6}
                                    />
                                </FormField>

                                {/* <Box>{formik.errors && <pre>{JSON.stringify(formik.errors, null, 2)}</pre>}</Box> */}

                                {!isValid && (
                                    <Box>
                                        <StyledErrors fill>
                                            Please complete required fields before submitting
                                        </StyledErrors>
                                    </Box>
                                )}
                                <Box direction="row" gap="medium" pad={{ horizontal: 'small', vertical: 'medium' }}>
                                    <Button
                                        type="submit"
                                        primary
                                        label={
                                            <Text color="white" textAlign="center">
                                                {loading ? 'Sending' : 'Submit'}
                                            </Text>
                                        }
                                        disabled={!isValid || isSubmitting}
                                        color="accent-1"
                                    />
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
                {error && (
                    <Box>
                        <StyledErrors fill>Sorry could not send email please call us.</StyledErrors>
                    </Box>
                )}
                {hasSent && (
                    <Box>
                        <StyledSent fill>Thank you - Your message has been sent</StyledSent>
                        {data?.to}
                    </Box>
                )}
            </StyledFormWrap>
        </>
    );
};
